import axios from 'axios';
import { join } from 'lodash';
import qs from 'qs';
import { AuthEndpoint } from 'services/endpoints';

export const request = axios.create({
  // Timeout 30p
  baseURL: process.env.REACT_APP_API_URL || 'https://customers.scbclportal.fwd.co.th',
  timeout: 30 * 60 * 1000,
  withCredentials: true,
});

const checkIsDefaultRequest = (config: any) => {
  if (!config) return true;

  // for (let index = 0; index < DEFAULT_REQUEST_END_POINTS.length; index++)
  //   if (config.url === DEFAULT_REQUEST_END_POINTS[index]) return true;

  return false;
};

request.interceptors.request.use(
  function (config) {
    if (checkIsDefaultRequest(config)) return config;

    if (config.method === 'post' || config.method === 'patch' || config.method === 'put')
      config = {
        ...config,
        ...{
          data: qs.stringify(config.data),
        },
      };

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const mySession = window.sessionStorage;

export const generateSession = () => {
  const cryptoWindow = window.crypto;
  const array = new Uint32Array(2);
  const crId = '_' + join(cryptoWindow?.getRandomValues(array), '');
  mySession.setItem('sessionId', crId);
  return crId;
};
request.interceptors.request.use(
  (config: any) => {
    const sessionId = mySession.getItem('sessionId') || generateSession();
    Object.assign(config.headers, { apikey: (window as any)._env_?.REACT_APP_APIKEY });
    // Object.assign(config.headers, {
    //   'access-control-allow-origin': process.env.REACT_APP_API_URL || 'https://customers.scbclportal.fwd.co.th',
    // });
    if (sessionId) {
      Object.assign(config.headers, { 'session-id': sessionId });
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = (document.cookie as string).split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// export function setCookie(cname: string = 'token', cvalue: any, exdays: number = 7) {
//   const d = new Date();
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//   const expires = 'expires=' + d.toUTCString();
//   document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
// }
